import React from 'react';

function MarketList({ markets }) {
    if (!markets.length) {
        return <p>No markets found. Please try another location.</p>;
    }

    return (
        <div className="market-list">
            {markets.map((market, index) => (
                <div key={index} className="market-tile">
                    <h2>{market.name}</h2>
                    <p>{market.address}</p>
                    <p>Next Open: {market.nextOpen}</p>
                </div>
            ))}
        </div>
    );
}

export default MarketList;
