import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import SearchBar from './components/SearchBar';
import MarketList from './components/MarketList';
import './App.css';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-H7Q0LQ16M1');

function App() {
    const [location, setLocation] = useState(""); // User-provided location
    const [markets, setMarkets] = useState([]); // Nearby markets
    const [error, setError] = useState(""); // Error message


    // Open a website in a new tab
    const openWebsite = (url) => {
        if (url) {
            window.open(url, '_blank');
        } else {
            console.error("Invalid URL");
        }
    };


    // Fetch user's location using ipapi on page load
    useEffect(() => {

        /*
        // Call Google Tag Manager
        const tagManagerArgs = {
            gtmId: 'G-H7Q0LQ16M1'
        };
        TagManager.initialize(tagManagerArgs);
        */

        ReactGA.send({ hitType: "pageview", page: window.location.pathname });

        const fetchUserLocation = async () => {
            try {
                const response = await fetch("https://ipapi.co/json/");
                const data = await response.json();
                if (data && data.latitude && data.longitude) {
                    // Populate markets near the user's location
                    fetchMarkets(data.latitude, data.longitude, 15); // Default radius of 15 miles

                    // Update the hero-text content dynamically
                    const city = data.city || "your area";
                    const region = data.region || "";
                    if (city && region) {
                        document.querySelector('.hero-text').innerHTML = `
                            <span class="hero-main-text" style="font-size: .9em;">
                                Uncover the Best of ${city} at Farmers Markets Near You.
                            </span>
                            <br>
                            <span class="hero-sub-text">
                                Your next favorite Farmers Market is waiting just around the corner!
                            </span>
                        `;
                    } else {
                        document.querySelector('.hero-text').innerHTML = `
                            <span class="hero-main-text" style="font-size: .9em;">
                                Uncover the Best of your area at Farmers Markets Near You.
                            </span>
                            <br>
                            <span class="hero-sub-text">
                                Your next favorite Farmers Market is waiting just around the corner!
                            </span>
                        `;
                    }
                } else {
                    console.error("Could not fetch user location");
                }
            } catch (err) {
                console.error("Error fetching user location:", err);

                // Fallback hero-text content if location fetch fails
                document.querySelector('.hero-text').innerHTML = `
                    <span class="hero-main-text" style="font-size: .9em;">
                        Uncover the Best of your area at Farmers Markets Near You.
                    </span>
                    <br>
                    <span class="hero-sub-text">
                        Your next favorite Farmers Market is waiting just around the corner!
                    </span>
                `;
            }
        };

        fetchUserLocation();
    }, []); // Run once on component mount

    // Fetch markets based on coordinates and radius
    const fetchMarkets = async (lat, lng, radius) => {
        try {
            const response = await fetch(
                `/api/getMarkets?x=${lng}&y=${lat}&radius=${radius}`
            );
            const data = await response.json();
            setMarkets(data);
        } catch (err) {
            console.error("Error fetching markets:", err);
        }
    };

    // Handle Search button click
    const handleSearch = async () => {
        if (!location) {
            setError("Please enter a valid location");
            return;
        }
        setError("");
        try {
            const response = await fetch(`/api/getCords?location=${location}`);
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                fetchMarkets(lat, lng, 15); // Default radius of 15 miles
            } else {
                setError("No results found for the given location");
            }
        } catch (err) {
            console.error("Error fetching coordinates:", err);
            setError("Failed to fetch coordinates. Please try again.");
        }
    };

    return (
        <div className="App">
            <header>Farmers Market Finder</header>

            {/* 
            Navigation Bar: 
            <nav>
                <a href="#">Home</a>
                <a href="#markets">Markets</a>
                <a href="#contact">Contact</a>
            </nav>
            */}


            <div className="hero">
                <div className="hero-text">
                    Discover Farmers Markets Near You!
                </div>
            </div>

            <div className="search-bar">
                <h3>Locate Fresh Markets</h3>
                <div className="search-input-container">
                    <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Enter a location"
                    />
                    <button onClick={handleSearch}>Search</button>
                </div>
                {error && <p className="error">{error}</p>}
            </div>

            <div className="markets">
            <h2 className="market-header">Farm-to-You: <strong>Local Farmers Markets</strong></h2>
                <div className="market-list">
                    {markets.length > 0 ? (
                        markets.map((market, index) => (
                            <div key={index} className="market-card">
                                <h3>{market.listing_name}</h3>
                                <p className="market-openMonth">
                                    {market.openInfo ? (
                                        <>
                                            <strong>{market.openInfo.label}</strong> {market.openInfo.content}
                                        </>
                                    ) : (
                                        <i></i>
                                    )}
                                </p>
                                <p className="market-foodOffer">
                                    {market.foodOffer ? (
                                        <>
                                            <strong>{market.foodOffer.label}</strong><br /> {market.foodOffer.content}
                                        </>
                                    ) : (
                                        <i></i>
                                    )}
                                </p>
                                <p>
                                    <strong>Address: </strong>
                                    <a href={market.address_link} target="_blank" rel="noopener noreferrer">{market.location_address}</a>
                                </p>
                                <button onClick={() => openWebsite(market.media_website)}>Visit Website</button>
                                <div className="market-distance"><p>{market.distance} <i>miles from you</i></p></div>
                            </div>
                        ))
                    ) : (
                        <p>No markets found. Try a different location.</p>
                    )}

                </div>
            </div>

        <footer>
            &copy; 2025 Farmers Market Finder. All rights reserved.
        </footer>

        </div>
    );
}

export default App;
